<script setup>

</script>
<style>
@media (max-width: 1024px) {
  .text-8xl {
    font-size: 3rem;
  }
  .max-w-200 {
    max-width: 150px;
  }
}
</style>
<template>
  <main class="relative bg-black min-h-screen flex justify-center items-center backdrop-blur-3xl" >
    <div class="flex flex-col align-middle items-center justify-center" style="max-width: fit-content ; height: auto; overflow: hidden">
      <div class="text-white text-8xl text-center mb-8">
        <h1>Coming Soon...</h1>
      </div>
      <div class="max-w-200 mx-auto">
        <img src="/logo.png" alt="Your Logo"/>
      </div>
      <div class="text-amber-200 my-4 text-8xl text-center mt-4">
        <h1>Gold Wings Ventures</h1>
      </div>
    </div>
  </main>
</template>
  
